/*App Centralization*/
.App {
  text-align: center;    
}

/*Header CSS*/
.App-header {
  margin-top: 40px;  
  background-color: #FFFFFF;
  min-height: 0vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
  font: Arial;
  font-size: 45px;
  color: black;
}

/*Home CSS*/
.Home {  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font: Arial;
}

/*Menu CSS*/
.App-header a {
  text-decoration: none;
  color: black;
  padding: 10px;
}

.menu-link:hover {
  color: #00b8ff;
}


.Menu {
  color: black;
  font: Arial;
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

/*Header Decorative Line CSS*/
hr {
  border-top: 1px solid black;
}

/*Images responsiviness*/
.img {
  max-width:100%;
}

.Home {
  font-size: 30px;
  font: Arial; 
  margin-bottom: 20px;
}

/*Buttons CSS*/
button {
  border: none;
  border-radius: 20px;
  background-color: #f7be2f;
  cursor: auto;
  width: 250px;
  height: 40px;
  font: Serif;
  font-weight: bold;
  font-size: 16px;  
  color: black;
  margin-top: 20px;  
  cursor: pointer;
}

button:hover{ 
  font-size: 16px;
  font: Serif;
  font-weight: bold;
  color: black;
  background-color: #bb6e01;
}

.mint-button {
  background-color: #f7be2f;  
  border-radius: 10px;  
  color: white;  
  width: 25px;
  height: 25px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 8px;
}

.mint-button:hover {
  background-color: #bb6e01;  
  color: white;
  width: 25px;
  height: 25px;
  margin-bottom: 0px;
}

.mint-button-font {
  font: Arial;
  font-weight: bold;
  font-size: 16px;
}


/*Terms and Conditions CSS*/
.Terms{
  margin-bottom: 50px;
}

.Terms a {    
  font: Arial;
  font-size: 11px;      
  text-decoration: underline;  
  color: black;
}

/*Social Bar*/
.Social {
  display: inline;  
  padding: 0px;
}

/*Meet Billys CSS*/
.Texts {
  font: Arial;
  font-size: 15px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  letter-spacing: 0.5px;  
}

.Texts a {
  text-decoration: underline;
  color: black;
}

/*Billys Future CSS*/
.Texts2 {
  font: Arial;
  font-size: 15px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  letter-spacing: 0.5px; 
  margin-bottom: 40px;   
  margin-top: 20px;
    
}

.Texts2 a {
  text-decoration: underline;
  color: black;

}

.Texts3 {
  font: Arial;
  font-size: 20px;
  text-align: center;    
}

/*Billys Only CSS*/
.billyonly {
  margin-top: 100px;
  margin-bottom: 100px;
}

/*Error CSS*/
.Error {
  font: Arial;
  font-size: 30px;  
  margin-bottom: 40px;
}

.notBillyButton {
  font-size: 15px;
  margin-bottom: 80px;
  margin-top: 50px;
}

.notBillyButton:hover {
  font-size: 15px;
  
}

.feedback {
  font: Arial;
  margin-bottom: 30px;
  margin-top: 10px;
}

.vote-results {
  display: flex;
  flex-direction: column;
  font-size: 18px;  
}

.billysonly-message {
  font: Arial;
  margin-bottom: 10px;
  font-size: 20px;
}